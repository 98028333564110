

@keyframes rightToLeft {
  from {
    opacity: 0;
    transform: translateX(100%); 
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animate-right-to-left {
    display:flex;
  animation: rightToLeft 1s ease-in-out;
}
